/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background: #2a2f43;
}
.red-snackbar {
    background: #ff0000;
    color: white;
    text-align: center;
    .mat-button-wrapper {
        color: #ccc;
    }
}
.special-snackbar {
    background: green;
    color: white;
    .mat-button-wrapper {
        color: #ccc;
    }
}
.cdk-overlay-pane.my-dialog {
    position: relative !important;
    .close.mat-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        line-height: 14px;
        min-width: auto;
    }
}

